<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						구매하기
					</h3>
					<a href="#" class="btn-close">
						<i class="icon icon-close--light"></i>
					</a>
				</header>
				<div class="layer__body">
					<div class="layer__bgbox">
						<div class="tickets">
							<div class="ticket">
								<div class="ticket__info">
									<span class="ticket__infotitle">
										1인 이용권
									</span>
									<span class="ticket__infodesc">
										동영상 촬영 미포함
									</span>
								</div>
								<div class="ticket__amount flex">
									<div class="amount">
										<button type="button" class="btn-amount btn-amount--minus">-</button>
										<span class="amount__count active"> 2 </span>
										<button type="button" class="btn-amount btn-amount--plus">+</button>
									</div>
									<div class="list__info-price info-price">
										<div class="info-price__sale">
											<strong>14%</strong>
											<span>35,000</span>
										</div>
										<div class="info-price__current">
											<strong>25,000</strong>원
										</div>
									</div>
								</div>
							</div>
							<div class="ticket">
								<div class="ticket__info">
									<span class="ticket__infotitle">
										1인 이용권
									</span>
									<span class="ticket__infodesc">
										동영상 촬영 미포함
									</span>
								</div>
								<div class="ticket__amount flex">
									<div class="amount">
										<button type="button" class="btn-amount btn-amount--minus">-</button>
										<span class="amount__count"> 0 </span>
										<button type="button" class="btn-amount btn-amount--plus">+</button>
									</div>
									<div class="list__info-price info-price">
										<div class="info-price__sale">
											<strong>14%</strong>
											<span>35,000</span>
										</div>
										<div class="info-price__current">
											<strong>25,000</strong>원
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="navigation-button">
							<a class="btn btn-confirm">바로 구매하기</a>
							<ul class="item__menu">
								<li>
									<button type="button"><i class="icon icon-cart">장바구니</i></button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>